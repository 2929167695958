import React, { useEffect, useRef, useState } from 'react';
import { Form, Card, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';

import 'assets/scss/page/_edit_profile.scss';
import { TNButton, TNBreadCrumb } from 'common';
import { useAddCategory, useEditCategory, useGetParentCategory, useViewCategory } from 'hooks';
import { defaultValue, imagePreviewFormik } from 'helpers';
import { commonOption, CATEGORIES, LIST, DASHBOARD } from 'config';

// Validation Schema
const validationSchema = Yup.object({
  // Define your validation schema here
});

const EditCategory = ({ t }) => {
  const { category_id } = useParams();
  const categoryType = localStorage.getItem('selectedCategory');
  const [parentOptions, setParentOptions] = useState([]);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const navigate = useNavigate();
  const ImageRef = useRef();
  const options = commonOption(t);

  const { mutate: doEditCategory, isLoading } = useEditCategory((response) => {
    alert('s');
    toast.success(response.message);
    navigate(CATEGORIES + LIST);
  });

  const { data: viewCategoryData, isLoading: viewCategoryLoading } = useViewCategory(
    Number(category_id),
    (data) => {
      // Handle successful data fetch
      if (data) {
        (formik.values.category_type = data?.data?.category_type),
          (formik.values.sequence = data?.data?.sequence),
          (formik.values.keyword = data?.data?.keyword),
          (formik.values.category_name = data?.data?.category_name),
          (formik.values.category_image = data?.data?.category_image),
          (formik.values.status = data?.data?.status),
          (formik.values.parent_id = data?.data?.parent_id),
          setInitialDataLoaded(true);
      }
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        navigate(DASHBOARD);
      }
    }
  );

  const { data: parentData } = useGetParentCategory();

  useEffect(() => {
    if (parentData) {
      setParentOptions(
        parentData.data.map((option) => ({
          value: option.category_id,
          label: option.category_name,
        }))
      );
    }
  }, [parentData]);

  useEffect(() => {
    if (initialDataLoaded) {
      // Ensure the form is updated after parent options are set
      const selectedParentOption = parentOptions.find(
        (option) => option.value === formik.values.parent_id
      );
      formik.setFieldValue('parent_id', selectedParentOption ? selectedParentOption.value : '');
    }
  }, [parentOptions, initialDataLoaded]);

  const handleCancel = () => {
    if (formik.dirty) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={onClose} />
            <div className="alert-popup">
              <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  onClose();
                  navigate(CATEGORIES + LIST);
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        ),
      });
    } else {
      navigate(CATEGORIES + LIST);
    }
  };

  const formik = useFormik({
    initialValues: {
      category_type:
        categoryType === 'sub-category' ? 2 : categoryType === 'parent-category' ? 1 : 3,
      sequence: '',
      keyword: '',
      category_name: '',
      category_image: '',
      status: '',
      parent_id: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      values.category_id = Number(category_id);
      doEditCategory(values);
    },
  });

  const breadcrumbArray = [
    {
      label: t('page.category_list_label'),
      link: CATEGORIES + LIST,
      active: '',
    },
    {
      label: t('page.edit_category_label'),
      link: '',
      active: 'active',
    },
  ];

  // Correctly match selected option value
  const selectedParentOption = parentOptions?.find(
    (option) => option?.value == formik.values.parent_id
  );

  return (
    <>
      <TNBreadCrumb breadCrumbArray={breadcrumbArray} />
      <Card className="inner-box">
        <h1 className="page-heading-center">{t('page.edit_category_label')}</h1>
        <div className="edit-profile-form">
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              {viewCategoryData?.data?.category_type == 2 && (
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top form-label">
                      {t('page.parent_category_label')}
                    </Form.Label>
                    <Select
                      placeholder={t('page.select_parent_category')}
                      options={parentOptions}
                      name="parent_id"
                      onChange={(selectedOption) => {
                        formik.setFieldValue(
                          'parent_id',
                          selectedOption ? selectedOption.value : ''
                        );
                      }}
                      value={selectedParentOption}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.parent_id && formik.errors.parent_id ? (
                        <div>{t(formik.errors.parent_id)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
              )}

              {/* Rest of the form fields */}
              <Col lg={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top form-label">
                    {t('page.category_category_name_label')}
                  </Form.Label>
                  <Form.Control
                    className={
                      'form-field-height ' +
                      (formik.touched.category_name && formik.errors.category_name
                        ? 'form-field-error'
                        : formik.touched.category_name && !formik.errors.category_name
                        ? 'form-field-success'
                        : '')
                    }
                    type="text"
                    name="category_name"
                    placeholder={t('page.category_category_name_placeholder')}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue('category_name', value.replace(/^\s+/g, ''));
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.category_name}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.category_name && formik.errors.category_name ? (
                      <div>{t(formik.errors.category_name)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top form-label">
                    {t('page.category_image_label')}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    hidden
                    ref={ImageRef}
                    name="category_image"
                    accept=".jpg, .jpeg, .png, .svg"
                    onChange={(event) => {
                      formik.setFieldValue('category_image', event.currentTarget.files[0]);
                    }}
                  />
                  <Button
                    type="button"
                    className="upload-button"
                    onClick={() => ImageRef.current?.click()}>
                    {t('page.file_upload')}
                  </Button>
                  <div className="preview-image">
                    {formik.values.category_image && (
                      <div>
                        <img
                          src={imagePreviewFormik(formik.values.category_image)}
                          width="100px"
                          alt="img"
                        />
                        <FontAwesomeIcon
                          icon={faClose}
                          onClick={() => {
                            formik.setFieldValue('category_image', '');
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Form.Group>
                <div className="form-field-error-text">
                  {formik.touched.category_image && formik.errors.category_image ? (
                    <div>{t(formik.errors.category_image)}</div>
                  ) : null}
                </div>
              </Col>
              <Col lg={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top form-label">
                    {t('page.category_keyword_label')}
                  </Form.Label>
                  <Form.Control
                    className={
                      ' form-field-height' +
                      (formik.touched.keyword && formik.errors.keyword
                        ? 'form-field-error'
                        : formik.touched.keyword && !formik.errors.keyword
                        ? 'form-field-success'
                        : '')
                    }
                    type="text"
                    name="keyword"
                    placeholder={t('page.category_keyword_placeholder')}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue('keyword', value.replace(/^\s+/g, ''));
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.keyword}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.keyword && formik.errors.keyword ? (
                      <div>{t(formik.errors.keyword)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top form-label">
                    {t('page.category_sequence_label')}
                  </Form.Label>
                  <Form.Control
                    className={
                      ' form-field-height' +
                      (formik.touched.sequence && formik.errors.sequence
                        ? 'form-field-error'
                        : formik.touched.sequence && !formik.errors.sequence
                        ? 'form-field-success'
                        : '')
                    }
                    type="text"
                    name="sequence"
                    placeholder={t('page.category_sequence_placeholder')}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue('sequence', value.replace(/^\s+/g, ''));
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.sequence}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.sequence && formik.errors.sequence ? (
                      <div>{t(formik.errors.sequence)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top form-label">
                    {t('page.category_is_active_label')}
                  </Form.Label>
                  <Select
                    placeholder={t('page.select_status')}
                    options={options}
                    className={
                      'form-field-height ' +
                      (formik.touched.status && formik.errors.status
                        ? 'form-select-error'
                        : formik.touched.status && !formik.errors.status
                        ? 'form-select-success'
                        : '')
                    }
                    value={defaultValue(options, formik.values.status)}
                    onChange={(selectedOption) => {
                      formik.setFieldValue('status', selectedOption.value);
                    }}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.status && formik.errors.status ? (
                      <div>{t(formik.errors.status)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <div className="primary-button">
              <span className="link-center" onClick={handleCancel}>
                {t('page.cancel_button_text')}
              </span>
              <TNButton type="submit" loading={isLoading} isDirtyForm={formik.dirty ? 1 : 0}>
                {t('page.save_button_text')}
              </TNButton>
            </div>
          </Form>
        </div>
      </Card>
    </>
  );
};

EditCategory.propTypes = {
  t: PropTypes.func.isRequired,
};

export default EditCategory;
