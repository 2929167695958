export const commonOption = (t) => {
  return [
    { value: '', label: `${t('page.select_status')}` },
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 2, label: `${t('page.in_active_status_name')}` },
  ];
};

export const userStatusOption = (t) => {
  return [
    { value: '', label: `${t('page.users_user_status_name')}` },
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 2, label: `${t('page.in_active_status_name')}` },
  ];
};
export const adminStatusOption = (t) => {
  return [
    { value: '', label: `${t('page.users_admin_status_name')}` },
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 2, label: `${t('page.in_active_status_name')}` },
  ];
};
export const userAccessOptions = (t) => {
  return [
    { value: '', label: `${t('page.users_access_name')}` },
    { value: 2, label: `${t('page.users_user_type_user')}` },
  ];
};
export const userSignupTypeOptions = (t) => {
  return [
    { value: '', label: `${t('page.users_signup_type_name')}` },
    { value: 1, label: `${t('page.users_signup_type_one')}` },
    { value: 2, label: `${t('page.users_signup_type_two')}` },
    { value: 3, label: `${t('page.users_signup_type_three')}` },
    { value: 4, label: `${t('page.users_signup_type_four')}` },
  ];
};

export const categoryTypeOption = (t) => {
  return [
    { value: '', label: `${t('page.category_category_type')}` },
    { value: 1, label: `${t('page.category_parent')}` },
    { value: 2, label: `${t('page.category_sub')}` },
    { value: 3, label: `${t('page.category_individual')}` },
  ];
};
export const attributesTypeOption = (t) => {
  return [
    { value: '', label: `${t('page.attributes_type')}` },
    { value: 1, label: `${t('page.attributes_text_input')}` },
    { value: 2, label: `${t('page.attributes_dropdown')}` },
  ];
};
