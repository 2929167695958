import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LIST, ADD, EDIT } from 'config';
import { VIEW } from 'config';

const AttributesList = React.lazy(() => import('./AttributesList'));
const AddAttributes = React.lazy(() => import('./AddAttributes'));
const ViewAttributes = React.lazy(() => import('./ViewAttributes'));
const EditAttributes = React.lazy(() => import('./EditAttributes'));

const AttributesRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path={LIST} element={<AttributesList t={t} />} />
      <Route exact path={ADD} element={<AddAttributes t={t} />} />
      <Route exact path={VIEW + ':attribute_id'} element={<ViewAttributes t={t} />} />
      <Route exact path={EDIT + ':attribute_id'} element={<EditAttributes t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AttributesRoute.propTypes = {
  t: PropTypes.func,
};
export default AttributesRoute;
